import React, { Fragment } from "react";
import { Link } from "gatsby";
import { Button } from "../../style/Button";
import Maintenance from "../../components/Maintenance";
import { Hp } from "../../components/CtaModul";
import { MarkupToHtml } from "../../helpers/parse";
import BreadcrumbsV2 from "../../components/breadcrumbsV2";
import { Container, Flex, Box } from "../../style/Grid";
import { StandardOffers } from "./StandardOffers";

const FixedProductsBanner = ({
  title,
  text,
  offers,
  textBottom,
  bottomLinkList,
  buttonTitle,
  buttonLink,
  fixedProductsBanner,
  showBreadcrumbs,
  children,
  bannerImageURL,
  additionalParams = undefined,
  bannerSmallImageURL,
  smallImageCustomAdditiveClass,
  customStyles = undefined,
  showPriceWithoutTax = false,
}) =>
{
  const showBannerButton = !!buttonTitle && !!buttonLink;

  const BASE_CONTENT_URL = process.env.GATSBY_MEDIA_LIBRARY_URL;
  const bannerLargeImageURL = fixedProductsBanner.largeImage.data.attributes.url;
  const dataBannerSmallImageURL = fixedProductsBanner.smallImage.data.attributes.url;

  const altText = "Získejte jistotu dodávky od MND";

  const largeImgClass = "w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover mb-0";

  const smallImgClass = `
    h-full
    w-full
    object-cover
    object-[-140px_-15px]
    ${smallImageCustomAdditiveClass ?? ``}
  `;

  return (
    <Fragment>
      <div className={`relative bg-resource-green-100 pb-10 ${customStyles?.additive?.wrapper}`}>
        <div className={"relative bg-exploratory-green-600 bg-left-bottom md:bg-bottom bg-no-repeat bg-cover md:bg-auto hp-image-container"}>

          {/* Desktop banner */}
          <div className={"hidden sm:block absolute top-0 left-0 right-0 bottom-0 h-full mb-0"}>
            <img
              src={bannerImageURL ?? `${BASE_CONTENT_URL}${bannerLargeImageURL}`}
              className={largeImgClass}
              alt={altText}
            />
          </div>

          {/* Mobile banner */}
          <div className={"block sm:hidden absolute top-0 left-0 right-0 bottom-0 h-full mb-0"}>
            <img
              src={bannerSmallImageURL ?? `${BASE_CONTENT_URL}${dataBannerSmallImageURL}`}
              className={smallImgClass}
              alt={altText}
            />
          </div>

          {showBreadcrumbs && (<>
            <Container mx={"auto"}>
              <Flex>
                <Flex flexDirection="column" width={1}>
                  <Box position="absolute">
                    <BreadcrumbsV2
                      pages={[
                        {
                          name: "Nabídka pro menší firmy",
                          link: null,
                        },
                      ]}
                      color={"white.100"}
                      homepageTitle="Úvod"
                    />
                  </Box>
                </Flex>
              </Flex>
            </Container>
          </>)}
          <div className={"relative mx-auto max-w-screen-lg"}>
            <div className={"relative mw-full flex flex-col md:relative px-4 pb-9 min-h-[430px] max-w-[732px] hidden sm:block"}>
              <div className={"absolute top-70px"}>
                {children || children?.length > 0 ? children : (<>
                  <h1 className={"text-H1 text-[38px] leading-tight mb-2 text-exploratory-green-400"}>
                    <MarkupToHtml>
                      {title}
                    </MarkupToHtml>
                  </h1>
                  <p className={"text-H4 mb-0 text-exploratory-green-400 font-bold"}>
                    <MarkupToHtml>
                      {text}
                    </MarkupToHtml>
                  </p>
                  {showBannerButton ? (
                    <Button flex={["0 1 206px"]} maxWidth={"206px"} variant={"greenDark"} as={Link} to={buttonLink} mt={3} ml={[1]} mr={[1]}>
                      {buttonTitle}
                    </Button>
                  ) : null}
                </>)}
              </div>
            </div>
            <div className={"relative mw-full flex flex-col px-4 pb-9 min-h-[480px] sm:hidden -bottom-180px"}>
              <div className={"absolute block  bottom-0 left-0 right-0 w-full bg-exploratory-green-gradient px-5 pt-6 pb-100px"}>
                <h1 className={"mb-2 text-white mt-auto fs-7 font-bold"}>
                  <MarkupToHtml>
                    {title}
                  </MarkupToHtml>
                </h1>
                <p className={"text-link mb-0 text-white font-semibold"} >
                  <MarkupToHtml>
                    {text}
                  </MarkupToHtml>
                </p>
                {showBannerButton ? (
                  <Button flex={["0 1 220px"]} maxWidth={"220px"} variant={"primary"} as={Link} to={buttonLink} mt={3} ml={[1]} mr={[1]}>
                    {buttonTitle}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={"relative z-50"}>
          <StandardOffers
            offers={offers}
            bottomLinkList={bottomLinkList}
            additionalParams={additionalParams}
            showPriceWithoutTax={showPriceWithoutTax}
            textBottom={textBottom}
          />
        </div>
      </div>
    </Fragment >
  );
};

export default FixedProductsBanner;
