import React from "react";
import Maintenance from "../../components/Maintenance";
import { Hp } from "../../components/CtaModul";
import { MarkupToHtml } from "../../helpers/parse";

export const StandardOffers = ({
    offers,
    bottomLinkList,
    additionalParams,
    showPriceWithoutTax,
    textBottom,

    customStyles = undefined,
}) => {
    return (
        <>
            <div className={`container mx-auto max-w-screen-lg px-4 -mt-20 relative ${customStyles?.wrapper?.additive ?? ""}`}>
                <Maintenance sticky={true} padding={[30, null, 100]} />
                <div className={"flex flex-col sm:flex-row justify-between w-full gap-5"}>
                <Hp
                    commodity={"gas"}
                    offers={offers.filter(o => o.commodity === "gas")}
                    bottomLinkList={bottomLinkList}
                    additionalParams={additionalParams}
                    showPriceWithoutTax={showPriceWithoutTax}
                />
                <Hp
                    commodity={"electricity"}
                    offers={offers.filter(o => o.commodity === "electricity")}
                    bottomLinkList={bottomLinkList}
                    additionalParams={additionalParams}
                    showPriceWithoutTax={showPriceWithoutTax}
                />
                </div>
            </div>

            {textBottom ? (
                <div className={"text-body text-center pt-6 sm:pt-10 px-4 mb-0 m-auto text-exploratory-green-400"} style={{ maxWidth: 720 }}>
                <MarkupToHtml>{textBottom}</MarkupToHtml>
                </div>
            ) : null}
        </>
    );
}